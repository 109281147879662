<template>
    <div class="historyInsect_box wh100">
        <div 
         element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(40, 53, 63, 0.1)"
         v-loading="listLoading"
         class="content_box video-box-bg-image wh100">
             <div class="tools_box">
                <div class="back_box active cp" @click="backRoute">
                   <span class="el-icon-back"></span>
                </div>
                <HnDateSearch @update='dateUpdate'/>
             </div>
             <div class="echarts_content_box boxBg">
                 <template v-if="chartsData">
                      <div class="wh100" ref="lineDome"></div>
                 </template>
                <template v-else> 
                  <hn-emptyData text= '暂无数据' size= '180px'></hn-emptyData>
                </template>
             </div>
             <div class="table_content_box">
                  <el-table
                    :data="tableData"
                    class="insectTable"
                    :header-row-class-name="tableRowClassName"
                     height="100%"
                    :row-class-name= 'tableRow'
                    :row-style="{height: '28px'}"
                    :cell-style="{padding: '8px 0'}"
                    >
                        <el-table-column
                            prop="at"
                            label="环境温度(℃)"
                            align="center"
                            :formatter="row => `${row.at}℃`"/>
                        <el-table-column
                            prop="ah"
                            label="环境湿度(%)"
                            align="center"
                            :formatter="row => `${row.ah}%`"/>
                        <el-table-column
                            prop="hrt"
                            label="加热仓实时温度(℃)"
                            align="center"
                            :formatter="row => `${row.hrt}℃`"/>
                        <el-table-column
                            prop="rps"
                            label="雨控状态"
                            align="center"
                            :formatter="row => row.rps == 1? '雨控':'正常'"/>
                        <el-table-column
                            prop="lps"
                            label="光控状态"
                            align="center"
                            :formatter="row => row.lps == 1? '光控':'正常'"/>
                        <el-table-column
                            prop="tps"
                            label="温控状态"
                            align="center"
                            :formatter="row => row.tps == 1? '温控':'正常'"/>
                        <el-table-column
                            prop="ws"
                            label="工作状态"
                            align="center"
                        >
                        <template slot-scope="scope">
                             <span :class="scope.row.ws == 1?'baseColor':'standbyColor'">
                                 {{ scope.row.ws == 1?'工作':'待机' }}
                             </span>
                         </template>
                        </el-table-column>
                        <el-table-column
                            prop="lamp"
                            label="灯管状态"
                            align="center"
                        >
                         <template slot-scope="scope">
                              <el-tooltip class="item" effect="dark" :content="$formatter(scope.row.lamp,'lampType')" placement="top">
                                <span :class="['el-icon-s-opportunity', 'f16',scope.row.lamp == 1?'baseColor':'offLineColor']"></span>
                              </el-tooltip>
                             
                         </template>
                        </el-table-column>
                        <!-- <el-table-column
                            prop="lux"
                            label="光照强度"
                            align="center"
                            :formatter="row => `${row.lux}lux`"/> -->
                        <el-table-column
                            prop="stamp"
                            label="记录时间"
                            align="center"/>
                  </el-table>
             </div>
        </div>
    </div>
</template>

<script>
    import { optionBarLine } from '@/libs/echartsOption'
    export default {

        data(){
          return {
             tableData:[],
             dataFromInfo:{
                  devId: '',
                  timeType: 3, 
                  sTime:'',
                  eTime: ''
             },
             picTotal: 10,
             chartsData: null,
             listLoading: false
               
          }
        },
        created(){
            let routeData = this.$route.query
            if(routeData && routeData.devId){
                this.$set(this.dataFromInfo,'devId',routeData.devId)
                this.reqIotDevInsectStateDataDetail()
            }
   
        },
        methods:{
          
          async reqIotDevInsectStateDataDetail(){
              this.listLoading= true
              const res = await this.$api.IotDevInsectStateDataDetail(this.dataFromInfo)
              setTimeout(()=> {
                    this.listLoading= false
             },500)
              if(res){
                 let { xAxis, charts,tables} = res
                 if(tables && tables.length) this.tableData = tables
                 else this.tableData = [] 
                 if(xAxis && xAxis.length){
                    this.chartsData = { xAxis, charts }  
                    this.$nextTick(()=> {
                        this.initEcharts('lineDome',optionBarLine(this.$echarts,this.chartsData))
                    })
                 }else {
                    this.chartsData = null
                 }  
              }else{
                  this.tableData = []
                  this.chartsData = null
              }
              
          },
          dateUpdate(data){
             Object.assign(this.dataFromInfo,data)
             this.reqIotDevInsectStateDataDetail()
          },
          backRoute(){
             this.$router.go(-1);
          },
          // 初始化echarts
          initEcharts(dome,option,) {
                
                let mychart = this.$echarts.init(this.$refs[dome]) 
                mychart.setOption( option ) 
                window.addEventListener("resize", function() {
                    mychart.resize();
                }); 
          },
          tableRowClassName() {
            
                return 'header-row';
               
          },
          tableRow({rowIndex}){
                if(rowIndex%2 !== 0){
                 return 'table-row'
                }else{
                    return 'table-odd-row'
                }
          },
        }
    }
</script>

<style lang="scss" scoped>
 .historyInsect_box{
     box-sizing: border-box;
     padding: 20px 20px 0 20px;
     .content_box{
        box-sizing: border-box;
        padding: 40px 40px 20px 40px;
        .tools_box{
            display: flex;
            align-items: center;
            .back_box{
               width: 26px;
               height: 26px;
               border-radius: 50%;
               display: flex;
               align-items: center;
               justify-content: center;
               font-size: 18px;
               margin-right: 20px;
               color: #15232D;
            }
          
        }
        .echarts_content_box{
          width: 100%;
          height: 280px;
          border-radius: 10px;
          margin-top: 15px;
        }
        .table_content_box{
          width: 100%;
          height: 360px;
          margin-top: 15px;
          .insectTable{
            background-color: transparent !important;
            &::before{
                height: 0; 
            }
          }
          .insectTable ::v-deep .header-row{
              background: #27343E;
              border-radius: 8px;
              & > th{
                // background: rgba(255,144,0,0.2);
                background: rgba(0,0,0,0);
                height: 28px;
                padding: 8px 0;
                color: #fff;
              }
                    
          }
          .insectTable ::v-deep .el-table__header-wrapper{
             border-radius: 8px;
          }
          .insectTable ::v-deep td.el-table__cell{
              border-bottom: none;
          }
          .insectTable ::v-deep th.el-table__cell.is-leaf{
              border-bottom: none;
          }
          .insectTable ::v-deep .el-table__row{
               border-radius: 8px;
                & > td:first-child{
                border-radius: 8px 0 0 8px;
              }
              & > td:last-child{
                border-radius: 0 8px 8px 0;
              }
          }
          .el-table ::v-deep .table-odd-row {
              background: transparent !important;
            //   & > td.el-table__cell{
            //       border-bottom: none;
            //   }
          }
          .el-table ::v-deep .el-table__body-wrapper {
              background: rgba(0,0,0,0) !important;
          }
           .el-table ::v-deep tbody tr:hover>td {
            background-color: rgba(0,195,149,0.1)!important
          }

        .el-table ::v-deep tbody tr:hover {
            background-color: rgba(0,195,149,0.1)!important
        }
          .el-table ::v-deep .table-row{
              background-color: #1C2933;
              
             
          }
        }
        .pages_box{
          margin-top: 15px;
           text-align: center;
        }
     }
 }
</style>